<template>
  <!--begin::PriceSearch step 2-->
  <div class="gray-box">
    <!--begin::Query data-->
    <div
      v-if="Routes[CurrentRoute]"
      class="white-box shadow-box py-6 px-12 mb-6"
      style="border-radius: 0"
    >
      <div class="row">
        <div class="col-lg-9">
          <div class="pb-3 border-bottom-200">
            <strong>{{ $t("PRICE_SEARCH.STEP1.ORIGIN") }}:</strong>
            {{ formatExtremeAddress(Routes[CurrentRoute].OriginAddress) }}
            <inline-svg src="/media/icons/trayecto-primary.svg" class="mx-6" />
            <strong>{{ $t("PRICE_SEARCH.STEP1.DESTINATION") }}:</strong>
            {{ formatExtremeAddress(Routes[CurrentRoute].DestinationAddress) }}
          </div>
          <div class="pt-3">
            <span
              v-for="whichLeg in Routes[CurrentRoute].Back.Enabled
                ? ['There', 'Back']
                : ['There']"
              :key="whichLeg"
              class="mr-6"
            >
              <span v-if="whichLeg === 'There'">
                <strong>
                  {{
                    Routes[CurrentRoute].Back.Enabled
                      ? $t("PRICE_SEARCH.STEP1.THERE")
                      : $t("PRICE_SEARCH.STEP1.JUST_THERE")
                  }}:
                </strong>
                {{
                  $tc(
                    "PRICE_SEARCH.STEP2.VEHICLE_COUNT",
                    RouteVehicleCountThere
                  )
                }}
              </span>
              <span v-else>
                <strong>{{ $t("PRICE_SEARCH.STEP1.BACK") }}:</strong>
                {{
                  $tc("PRICE_SEARCH.STEP2.VEHICLE_COUNT", RouteVehicleCountBack)
                }}
              </span>
              ,
              <span
                v-if="
                  Routes[CurrentRoute][whichLeg].DestinationDateDesiredText ===
                    'ONE_WEEK'
                "
              >
                {{ $t("PRICE_SEARCH.STEP2.DELIVERY_ONE_WEEK") }}
              </span>
              <span
                v-else-if="
                  Routes[CurrentRoute][whichLeg].DestinationDateDesiredText ===
                    'TWO_WEEKS'
                "
              >
                {{ $t("PRICE_SEARCH.STEP2.DELIVERY_TWO_WEEKS") }}
              </span>
              <span v-else>
                {{ $t("PRICE_SEARCH.STEP2.DELIVERY") }}
                <span
                  v-if="
                    Routes[CurrentRoute][whichLeg]
                      .DestinationDateDesiredText === 'SPECIFIC_DATE'
                  "
                >
                  {{
                    Routes[CurrentRoute][whichLeg]
                      .DestinationDateDesiredFromFormatted
                  }}
                </span>
                <span
                  v-else-if="
                    Routes[CurrentRoute][whichLeg]
                      .DestinationDateDesiredText === 'SPECIFIC_RANGE'
                  "
                >
                  {{
                    Routes[CurrentRoute][whichLeg]
                      .DestinationDateDesiredFromFormatted +
                      " - " +
                      Routes[CurrentRoute][whichLeg]
                        .DestinationDateDesiredToFormatted
                  }}
                </span>
                <span v-else>
                  {{ $t("PRICE_SEARCH.STEP1.FLEXIBLE") }}
                </span>
              </span>
            </span>
          </div>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-around">
          <button
            class="btn btn-pill btn-tertiary button-filter"
            @click="$router.push('/pricesearch/step1')"
          >
            <inline-svg src="/media/icons/search-alt-1.svg" class="mr-3" />
            {{ $t("PRICE_SEARCH.STEP2.NEW_SEARCH") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Query data-->

    <!--begin::Results intro-->
    <div class="px-12">
      <div class="row pb-3">
        <div class="col-lg-9">
          <span v-if="RouteResultsCount === 0">
            {{ $t("PRICE_SEARCH.STEP2.OFFER_COUNT_0") }}
          </span>
          <span v-else>
            {{ $t("PRICE_SEARCH.STEP2.OFFER_COUNT_1") }}
            <strong class="text-primary">
              {{ $tc("PRICE_SEARCH.STEP2.OFFER_COUNT_2", RouteResultsCount) }}
            </strong>
          </span>
          {{ $t("PRICE_SEARCH.STEP2.OFFER_COUNT_3") }}
          <div
            v-if="Routes[CurrentRoute] && Routes[CurrentRoute].Back.Enabled"
            class="mb-0"
          >
            {{ $t("PRICE_SEARCH.STEP2.OFFER_THERE_AND_BACK") }}
          </div>
          <br v-else />
          {{ $t("PRICE_SEARCH.STEP2.OFFER_COUNT_4") }}
        </div>

        <div class="col-lg-3 d-flex justify-content-end">
          <select
            id="SelectOrderResultsBy"
            v-if="RouteResultsCount > 1"
            class="form-control rounded-pill"
            v-model="OrderResultsBy"
          >
            <option value="LOW_PRICE" class="text-primary">
              {{ $t("PRICE_SEARCH.STEP2.LOW_PRICE") }}
            </option>
            <option value="HIGH_PRICE" class="text-primary">
              {{ $t("PRICE_SEARCH.STEP2.HIGH_PRICE") }}
            </option>
            <option value="LOW_DAYS" class="text-primary">
              {{ $t("PRICE_SEARCH.STEP2.LOW_DAYS") }}
            </option>
            <option value="HIGH_DAYS" class="text-primary">
              {{ $t("PRICE_SEARCH.STEP2.HIGH_DAYS") }}
            </option>
          </select>
        </div>
      </div>
      <hr />
    </div>
    <!--end::Results intro-->

    <!--begin::Results list-->
    <div
      class="row white-box shadow-box p-0 mx-12 my-6"
      style="border-radius: 0; width: auto !important"
      v-for="(transporter, transporterIndex) in RouteResultsOrdered"
      :key="transporterIndex"
    >
      <!--begin::Days-->
      <div
        class="col-lg-2 py-9 bg-primary text-white d-flex flex-column align-items-center justify-content-around"
      >
        <div class="d-flex align-items-center justify-content-around mb-3">
          <span
            class="daysSpan bg-tertiary d-flex align-items-center justify-content-around mr-3"
          >
            {{ transporter.EstimatedDays }}
          </span>
          <strong>{{ $t("PRICE_SEARCH.STEP2.DAYS") }}</strong>
        </div>
        <div>
          {{ $t("PRICE_SEARCH.STEP2.ESTIMATED_DELIVERY") }}
        </div>
      </div>
      <!--end::Days-->

      <!--begin::Company-->
      <div class="col-lg-7 pt-6 pb-3">
        <div class="row">
          <div class="col-lg-6 d-flex flex-column justify-content-between">
            <div class="row">
              <div class="col-lg-3">
                <v-img :src="transporter.Logo" class="companyLogo2 mx-6" />
              </div>
              <div class="col-lg-9">
                <div class="companyName">{{ transporter.FiscalName }}</div>
                <div>
                  {{
                    $t("PRICE_SEARCH.STEP2.FARE") +
                      " " +
                      $t("FARE_TYPE_NAMES_BY_ID." + transporter.FareTypeID)
                  }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2">
                &nbsp;
              </div>
              <div class="col-lg-10">
                <button
                  v-if="!DetailsOpen[transporterIndex]"
                  class="btn btn-pill btn-white button-form p-0"
                  @click="openDetails(transporterIndex)"
                >
                  <inline-svg
                    src="/media/icons/chevron-down-primary.svg"
                    class="mr-3"
                  />
                  {{ $t("GENERIC.DETAILS_OPEN") }}
                </button>

                <button
                  v-else
                  class="btn btn-pill btn-white button-form p-0"
                  @click="closeDetails(transporterIndex)"
                >
                  <inline-svg
                    src="/media/icons/chevron-up-primary.svg"
                    class="mr-3"
                  />
                  {{ $t("GENERIC.DETAILS_CLOSE") }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-lg-6 border-left-200 border-right-200">
            <v-timeline dense class="pt-3">
              <v-timeline-item small>
                <template v-slot:icon>
                  <inline-svg src="/media/icons/pin-1-primary.svg" />
                </template>
                <strong>{{ $t("PRICE_SEARCH.STEP1.ORIGIN") }}:</strong>
                <br />
                {{ formatExtremeAddress(Routes[CurrentRoute].OriginAddress) }}
              </v-timeline-item>
              <v-timeline-item small>
                <template v-slot:icon>
                  <inline-svg src="/media/icons/pin-1-primary.svg" />
                </template>
                <strong>{{ $t("PRICE_SEARCH.STEP1.DESTINATION") }}:</strong>
                <br />
                {{
                  formatExtremeAddress(Routes[CurrentRoute].DestinationAddress)
                }}
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </div>
      <!--end::Company-->

      <!--begin::Price-->
      <div class="col-lg-3 text-right py-6 pr-6">
        <div class="priceTotal">
          {{
            formatPrice(transporter.Price.Total) +
              " " +
              transporter.Price.Currency
          }}
          <span v-if="!transporter.Price.NoTaxes" class="text-danger">*</span>
        </div>
        <div class="mb-3 text-danger">
          &nbsp;
          <span v-if="!transporter.Price.NoTaxes">
            (*) {{ $t("PRICE_SEARCH.STEP2.TAXES_INCLUDED") }}
          </span>
        </div>
        <button
          class="btn btn-pill btn-tertiary button-form"
          @click="purchase(transporter.CacheIndex)"
        >
          {{ $t("PRICE_SEARCH.STEP2.PURCHASE_NOW") }}
        </button>
      </div>
      <!--end::Price-->

      <!--begin::Details-->
      <div class="col-lg-12 py-6 px-18" v-if="DetailsOpen[transporterIndex]">
        <p>
          {{ $t("GENERIC.DETAILS") }}
        </p>
        <p>
          <strong>{{ $t("PRICE_SEARCH.STEP2.TRANSPORTER_DATA") }}</strong>
        </p>
        <h5>
          {{ transporter.FiscalName }}
          <br />
          {{ $t("COMPANIES.FISCAL_ID_SHORT") + " " + transporter.FiscalID }}
        </h5>
        <p>
          <a
            :href="formatTransporterAddressGoogleMaps(transporter)"
            target="_blank"
          >
            <inline-svg src="/media/icons/map-primary.svg" class="mr-3" />
            {{ formatTransporterAddress(transporter) }}
          </a>
        </p>
        <div class="row">
          <div class="col-lg-6">
            <p>
              <strong>{{ $t("PRICE_SEARCH.STEP2.DETAILS_SERVICE") }}</strong>
            </p>
            <p
              v-if="
                !transporter.Conditions || transporter.Conditions.length < 1
              "
            >
              {{ $t("GENERIC.N_D") }}
            </p>
            <ul v-else class="ml-6">
              <li v-for="(conditionLine, c) in transporter.Conditions" :key="c">
                {{ conditionLine }}
              </li>
            </ul>
          </div>

          <div class="col-lg-6">
            <p>
              <strong>{{ $t("PRICE_SEARCH.STEP2.DETAILS_LOGIOK") }}</strong>
            </p>
            <ul class="ml-6">
              <li
                v-for="(conditionLine2, c2) in $t(
                  'PRICE_SEARCH.STEP2.CONDITIONS_LOGIOK'
                )"
                :key="c2"
              >
                {{ conditionLine2 }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--end::Details-->
    </div>
    <!--end::Results list-->
  </div>
  <!--end::PriceSearch step 2-->
</template>

<style scoped>
#SelectOrderResultsBy {
  font-size: 1em !important;
  width: 20em;
  background-image: url(/media/icons/chevron-down-primary.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
}
.daysSpan {
  width: 2em;
  height: 2em;
  font-size: 1.25em;
  font-weight: 800;
  border-radius: 50%;
  text-align: center;
}
.companyLogo2 {
  border: 0;
  width: 4em !important;
  height: auto;
}
.companyName {
  font-size: 1.25em;
  font-weight: 800;
}
.priceTotal {
  font-size: 1.75em;
  font-weight: 800;
}
</style>

<script>
import i18nService from "@/core/services/i18n.service.js";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";
import ProvinceService from "@/core/services/api/v2/province.service";

export default {
  name: "PriceSearchStep2",
  data() {
    return {
      ProvinceNames: [],
      CurrentRoute: 0,
      Routes: [],
      Results: [],
      OrderResultsBy: "LOW_PRICE",
      DetailsOpen: []
    };
  },
  computed: {
    RouteVehicleCountThere() {
      let sum = 0;

      if (this.Routes[this.CurrentRoute]) {
        this.Routes[this.CurrentRoute]["There"].Vehicles.forEach(v => {
          sum += v.Quantity;
        });
      }

      return sum;
    },
    RouteVehicleCountBack() {
      let sum = 0;

      if (this.Routes[this.CurrentRoute]) {
        if (this.Routes[this.CurrentRoute].Back.Enabled) {
          this.Routes[this.CurrentRoute]["Back"].Vehicles.forEach(v => {
            sum += v.Quantity;
          });

          // Back enabled but no vehicles = the same vehicles as There
          if (sum === 0) {
            sum = this.RouteVehicleCountThere;
          }
        }
      }

      return sum;
    },
    RouteResultsCount() {
      return this.Results[this.CurrentRoute]
        ? this.Results[this.CurrentRoute]["Transporters"].length
        : 0;
    },
    RouteResultsOrdered() {
      let list = this.Results[this.CurrentRoute]
        ? this.Results[this.CurrentRoute]["Transporters"]
        : [];

      if (this.OrderResultsBy === "LOW_DAYS") {
        return list.sort((a, b) => {
          let aDays = parseInt(a.EstimatedDays);
          let bDays = parseInt(b.EstimatedDays);
          if (aDays !== bDays) {
            return aDays - bDays;
          } else {
            // Equal days -> low price
            return parseFloat(a.Price.Total) - parseFloat(b.Price.Total);
          }
        });
      } else if (this.OrderResultsBy === "HIGH_DAYS") {
        return list.sort((a, b) => {
          let aDays = parseInt(a.EstimatedDays);
          let bDays = parseInt(b.EstimatedDays);
          if (aDays !== bDays) {
            return bDays - aDays;
          } else {
            // Equal days -> low price
            return parseFloat(a.Price.Total) - parseFloat(b.Price.Total);
          }
        });
      } else if (this.OrderResultsBy === "HIGH_PRICE") {
        return list.sort(
          (a, b) => parseFloat(b.Price.Total) - parseFloat(a.Price.Total)
        );
      } else {
        // Default -> low price
        return list.sort(
          (a, b) => parseFloat(a.Price.Total) - parseFloat(b.Price.Total)
        );
      }
    }
  },
  created() {
    this.loadProvinceNames();
  },
  methods: {
    loadProvinceNames() {
      this.ProvinceNames = [];
      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;

          this.loadStep1CachedData();
        });
      });
    },

    loadStep1CachedData() {
      this.Routes = PriceSearchService.getCachedRoutes()["Routes"];
      if (this.Routes.length < 1) {
        this.$router.push("/pricesearch/step1");
      }

      this.Results = PriceSearchService.getCachedResults()["Routes"];
      // Set a position index to trace them when reordered
      this.Results.forEach(r => {
        let cacheIndex = 0;
        r.Transporters.forEach(t => {
          t.CacheIndex = cacheIndex;
          cacheIndex++;
        });
      });

      let nothingOpen = [];
      for (let r = 0; r < this.RouteResultsCount; r++) {
        nothingOpen.push(false);
      }
      this.DetailsOpen = Object.assign({}, nothingOpen);
    },

    formatPrice(value) {
      let value2 = value.toFixed(2);
      let userLang = i18nService.getActiveLanguage();
      if (userLang === "en") {
        return value2;
      } else {
        return value2.replace(".", ",");
      }
    },

    openDetails(transporterIndex) {
      this.DetailsOpen[transporterIndex] = true;
    },
    closeDetails(transporterIndex) {
      this.DetailsOpen[transporterIndex] = false;
    },

    purchase(transporterCacheIndex) {
      this.$router.push(
        "/pricesearch/step3/" + this.CurrentRoute + "/" + transporterCacheIndex
      );
    },

    formatExtremeAddress(a) {
      return (
        a.PostalCode +
        " - " +
        a.City +
        " (" +
        this.ProvinceNames[a.ProvinceID] +
        ")"
      );
    },

    formatTransporterAddress(t) {
      return (
        t.Street +
        ". " +
        t.PostalCode +
        " - " +
        t.City +
        " (" +
        this.ProvinceNames[t.ProvinceID] +
        ")"
      );
    },

    formatTransporterAddressGoogleMaps(t) {
      let address = t.Street + ", " + t.PostalCode + ", " + t.City;
      return "https://www.google.es/maps/place/" + address.replaceAll(" ", "+");
    }
  }
};
</script>
